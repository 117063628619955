import React from 'react';
import { Nav } from '@/components/nav';
import { Meta } from '@/components/meta';
import { GetServerSideProps } from 'next';
import PostCard from '@/components/post-card';
import PostSearch from '@/components/post-card/search';
import { Container } from '@/components/container';
import { PostOrPage } from 'tryghost__content-api';
import { DownloadApp } from '@/components/download-app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useMobileResponsive } from '@/hooks';
import { cn } from '@/utils';
import SearchBlogComp from '../../components/blogs-components/_SearchBlogComp';
import PinnedBlogs from '../../components/blogs-components/_PinnedBlogs';
import LatestBlogSlider from '../../components/blogs-components/_LatestBlogSlider';
import axios from 'axios';
import { request } from 'http';
import { useSearch, SearchProvider } from '@/contexts/searchContext';

const pinnedBlogsData: any[] = [
  // {
  //   title: `The Story of the Birth of the Elephant-Headed God`,
  //   tags: [`SLEEP`, `STORIES`, `BELIEFS`, `LIFE LESSONS`],
  //   description: `With his endearing smile, large ears, and a single, graceful tusk, Lord Ganesha is one of the most recognizable and beloved deities.`,
  //   img: `https://blog.cdn.level.game/2024/08/bedtime-story-of-birth-of-ganesha-1.webp`,
  //   link: `https://level.game/blogs/lord-ganesha-story-of-birth?lang=en`,
  // },
  // {
  //   title: `Why is Ganesh's Tusk Broken?`,
  //   tags: [`SLEEP`, `STORIES`, `BELIEFS`, `LIFE LESSONS`],
  //   description: `With his endearing smile, large ears, and a single, graceful tusk, Lord Ganesha is one of the most recognizable and beloved deities.`,
  //   img: `https://blog.cdn.level.game/2024/08/why-ganesh-bappa-teeth-is-broken.webp`,
  //   link: `https://level.game/blogs/why-is-ganeshs-tusk-broken?lang=en`,
  // },
];

const popularTags = [
  { id: 1, name: `All` },
  { id: 2, name: `Stress and Anxiety` },
  { id: 3, name: `Exam Stress` },
  { id: 4, name: `Mythology` },
  { id: 5, name: `Fitness` },
  { id: 6, name: `Health` },
  { id: 7, name: `Time management` },
];

export default function Blog(props: { posts: PostOrPage[]; currPage: number }) {
  const [error, setError] = React.useState(``);
  const [posts, setPosts] = React.useState<PostOrPage[]>([]);
  const [currPage, setCurrPage] = React.useState(props.currPage);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [searchResult, setSearchResult] = React.useState<PostOrPage[]>([]);
  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [isAppTraffic, setIsAppTraffic] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);

  const { setSearchText, searchBlog } = useSearch();

  const getBlogPosts = async (page = 1) => {
    try {
      setIsLoading(true);
      const raw = await fetch(`/api/ghost-api?page=${page}`);

      if (!raw.ok) {
        setError(`Error fetching blog posts`);
        return;
      }

      const json = await raw.json();

      if (json.meta.current_page) {
        setCurrPage(json.meta.current_page);
      }

      setHasNextPage(!!json.meta.next_page);

      setPosts((prev) => [...prev, ...json.posts]);
      setSearchResult((prev) => [...prev, ...json.posts]);
    } catch (err) {
      setError(`Error fetching blog posts`);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const source = new URLSearchParams(window.location.search).get(`source`);
    if (source === `levelapp`) {
      setIsAppTraffic(true);
    }
  }, []);

  const renderPostCard = (post: PostOrPage) => {
    return (
      <PostSearch
        isAppTraffic={isAppTraffic}
        isMobile={isMobile}
        searchResult={searchResult}
        isSearching={isSearching}
        setIsSearchLoading={setIsSearchLoading}
        searchBlog={searchBlog1}
        isSearchLoading={undefined}
        tags={popularTags}
        key={post.slug}
        post={post}
      />
    );
  };

  // const searchBlog = async (query: string) => {
  //   setIsSearchLoading(true);

  //   try {
  //     let data = JSON.stringify({
  //       query: query,
  //     });

  //     let params = {
  //       query: query,
  //     };

  //     let config = {
  //       method: 'GET',
  //       maxBodyLength: Infinity,
  //       url: 'https://level-core-backend.api.level.game/v1/common/search-blog',
  //       headers: {
  //         Authorization:
  //           'auth eyJhbGciOiJIUzI1NiJ9.eyJwYWdlIjoxLCJsYW5ndWFnZSI6MX0.wUMQEEKLzp60qXFXUrFqUW6DnlZJ6wSc0-OMT63qV2s',
  //         'Content-Type': 'application/json',
  //       },
  //       // body: { query: query },
  //       body: params,
  //     };

  //     console.log(params, 'params');

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         console.log(response.data, 'jsonblog');
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (err) {
  //     console.error(`Error searching for blogs:`, err);
  //   } finally {
  //     setIsSearchLoading(false);
  //     setIsSearching(true);
  //   }
  // };

  // React.useEffect(() => {
  // }, [searchResult]);

  // const searchBlog1 = async (query: string) => {
  //   setIsSearchLoading(true);

  //   try {
  //     if (query && query.trim() !== '') {
  //       const api_data = JSON.stringify({
  //         query,
  //       });
  //       const response = await fetch(
  //         `https://level-core-backend.api.level.game/v1/common/search-blog`,
  //         {
  //           method: `POST`,
  //           headers: {
  //             'Content-Type': `application/json`,
  //             Authorization: `auth eyJhbGciOiJIUzI1NiJ9.eyJwYWdlIjoxLCJsYW5ndWFnZSI6MX0.wUMQEEKLzp60qXFXUrFqUW6DnlZJ6wSc0-OMT63qV2s`,
  //           },
  //           body: api_data,
  //         },
  //       );

  //       if (response.ok) {
  //         const data = await response.json();
  //         console.log(data, 'data');

  //         setSearchResult(data.flat());
  //       } else {
  //         console.error(`API Error:`, response.statusText);
  //       }
  //     } else {
  //       console.log('query cannot be empty');
  //     }
  //   } catch (err) {
  //     console.error(`Error searching for blogs:`, err);
  //   } finally {
  //     setIsSearchLoading(false);
  //     setIsSearching(true);
  //   }
  // };

  const searchBlog1 = async (query: string) => {
    try {
      setIsSearchLoading(true);
      setIsSearching(true);

      if (query && query.trim() !== ``) {
        const response = await fetch(
          `https://level-core-backend.api.level.game/v1/common/search-blog`,
          {
            method: `POST`,
            headers: {
              'Content-Type': `application/json`,
              Authorization: `auth eyJhbGciOiJIUzI1NiJ9.eyJwYWdlIjoxLCJsYW5ndWFnZSI6MX0.wUMQEEKLzp60qXFXUrFqUW6DnlZJ6wSc0-OMT63qV2s`,
            },
            body: JSON.stringify({ query }),
          },
        );

        if (response.ok) {
          const data = await response.json();
          setSearchResult(data);
        } else {
          console.error(`API Error:`, response.statusText);
        }
      } else {
        console.log(`query cannot be empty`);
      }
    } catch (err) {
      console.error(`Error searching for blogs:`, err);
    } finally {
      setIsSearchLoading(false);
    }
  };

  React.useEffect(() => {
    setPosts(props.posts);
  }, [props.posts]);

  React.useEffect(() => {
    setCurrPage(props.currPage);
  }, [props.currPage]);

  React.useEffect(() => {
    const source = new URLSearchParams(window.location.search).get(`source`);
    if (source === `levelapp`) {
      setIsAppTraffic(true);
    }
  }, []);

  const { isMobile } = useMobileResponsive();

  return (
    <SearchProvider searchBlog={searchBlog1}>
      <div
        className={cn(`bg-[#E5D9FF]`, {
          // 'bg-[#0F0622] text-[#FFFFFF] py-10': isAppTraffic,
        })}
      >
        <Nav />
        <Meta
          title="Blog | Level - Supercharge your Mind"
          url="https://level.game/blogs"
        />
        <Container>
          <SearchBlogComp
            isAppTraffic={isAppTraffic}
            isMobile={isMobile}
            searchResult={searchResult}
            isSearching={isSearching}
            setIsSearchLoading={setIsSearchLoading}
            searchBlog={searchBlog1}
            isSearchLoading={undefined}
            tags={popularTags}
          />

          <LatestBlogSlider posts={posts} />

          <div className="max-w-7xl mx-auto">
            <div
              className={
                searchResult.length !== 0 || isSearching
                  ? `py-2 rounded-lg mb-14`
                  : `py-2`
              }
            >
              {!isAppTraffic && (
                <div
                  style={{ display: !isMobile ? `flex` : `block` }}
                  className="md:flex justify-between items-center mt-20 px-6 my-4"
                >
                  <h3 className="text-3xl font-bold">All Blogs</h3>
                </div>
              )}

              <div
                className={[
                  `grid`,
                  `grid-cols-1`,
                  `sm:grid-cols-2`,
                  `md:grid-cols-3`,
                  `gap-12`,
                  `p-4`,
                ].join(` `)}
              >
                {pinnedBlogsData?.map((data, index) =>
                  searchResult.length > 0 ? (
                    <></>
                  ) : (
                    <PinnedBlogs data={data} key={index} />
                  ),
                )}
                {/* 
                {isSearching
                  ? searchResult?.map((post) => (
                      <>
                        <PostCard key={post.id} post={post} />
                      </>
                    ))
                  : posts.map((post) => renderPostCard(post))} */}

                {/* {isSearching && searchResult.length === 0 && (
                  <p className="col-span-full text-center font-bold text-2xl">
                    No results found related to your search
                  </p>
                )} */}
                {isSearching
                  ? searchResult.length > 0
                    ? searchResult.map((post) => (
                        <PostCard key={post.id} post={post} />
                      ))
                    : posts.map((post) => renderPostCard(post))
                  : posts.map((post) => renderPostCard(post))}
              </div>
            </div>

            <div
              className={[
                `grid`,
                `grid-cols-1`,
                `sm:grid-cols-2`,
                `md:grid-cols-3`,
                `gap-12`,
                `p-4`,
              ].join(` `)}
            ></div>
            {hasNextPage && (
              <button
                className={[
                  `mx-auto`,
                  `px-8`,
                  `py-4`,
                  `my-20`,
                  `border`,
                  `block`,
                  `border-[#141414]`,
                  `bg-white`,
                  `text-[#141414]`,
                  `font-bold`,
                  `rounded-lg`,
                  `opacity-40`,
                  `hover:opacity-100`,
                  `hover:bg-[#141414]`,
                  `hover:text-white`,
                  `transition-colors`,
                  `transition-opacity`,
                ].join(` `)}
                onClick={() => {
                  getBlogPosts(currPage + 1);
                }}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  `READ MORE`
                )}
              </button>
            )}
          </div>
        </Container>

        {!isAppTraffic && <DownloadApp />}
      </div>
    </SearchProvider>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const domain = context.req.headers.host;
  const proto = (context.req.headers[`x-forwarded-proto`] as string) || `http`;
  const hostname = domain?.startsWith(proto) ? domain : `${proto}://${domain}`;

  const props: { [key: string]: any } = {};

  try {
    context.query.page = context.query.page?.toString() || `1`;

    if (context.query.q) {
      const query = context.query.q.toString();

      const api_data = JSON.stringify({
        query,
      });

      const response = await fetch(
        `https://searchai.levelsupermind.com/activity/search-blog`,
        {
          method: `POST`,
          headers: {
            'Content-Type': `application/json`,
          },
          body: api_data,
        },
      );

      if (response.ok) {
        const data = await response.json();
        props.searchResults = data;
      } else {
        console.error(`API Error:`, response.statusText);
      }
    } else {
      const search = new URLSearchParams(context.query as any);
      const raw = await fetch(`${hostname}/api/ghost-api?${search.toString()}`);
      const json = await raw.json();
      props.posts = json.posts;
      props.currPage = json.meta.current_page || 1;
    }
  } catch (err) {
    console.error(`Error:`, err);
    props.posts = [];
  }

  return { props };
};
